import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPageContent from "../components/Blog"
import { StaticQuery, graphql } from "gatsby"
import {
  BLOG_PAGE_QUERY_REQUEST,
  BLOG_PAGE_QUERY_SUCCESS,
  BLOG_PAGE_QUERY_FAILED,
} from "../store/actionTypes"
import { useDispatch } from "react-redux"
const Blogs = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZTo5NDY=") {
          blog_contents {
            banner {
              img {
                sourceUrl
                title
              }
              highlightedText {
                en
                sv
              }
              preTitle {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            newsletterForm {
              buttonText {
                en
                sv
              }
              form {
                name
                label {
                  en
                  sv
                }
                errorMsg {
                  en
                  sv
                }
              }
              formText {
                sv
                en
              }
              formTitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
        posts(first: 150) {
          nodes {
            date
            slug
            title
            single_blog {
              featuredImages {
                thumbnailImage {
                  sourceUrl
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            tags {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: BLOG_PAGE_QUERY_REQUEST })
        dispatch({ type: BLOG_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <Layout>
            <SEO title="Blogs" pageId="cGFnZTo5NDY" />
            <BlogPageContent />
          </Layout>
        )
      }}
    />
  )
}

export default Blogs
