import React, { useState } from "react"
import { Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import axios from "axios"
import { navigate } from "gatsby-link"

const Newsletter = () => {
  const { language } = useSelector(state => state.global)
  const { newsletterForm } = useSelector(({ blogs }) => blogs)

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()
  const [isLoading, setLoader] = useState(false)
  const [formValues, setFormvalue] = useState({
    name: "",
    email: "",
    page: "Blog",
    typeOfInquirer: "Customer",
  })
  const handleInputChange = ({ name, value }) => {
    setFormvalue({ ...formValues, [name]: value })
  }

  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  const formValidation = {
    name: {
      required: "First Name is Required",
    },
    email: {
      required: "Email is Required",
      pattern: {
        value: emailRegex,
        message:
          language === "sv" ? "Ogiltig e-postadress" : "Invalid email address",
      },
    },
  }

  const formSubmit = () => {
    setLoader(true)
    const formData = new FormData()
    const updatedFormValues = {
      ...formValues,
      page: "Blog Newsletter",
      typeOfInquirer: "Customer",
      ...JSON.parse(localStorage.getItem("utmTags")),
    }
    Object.keys(updatedFormValues).forEach(key => {
      formData.append(key, updatedFormValues[key])
    })

    const URL =
      "https://script.google.com/macros/s/AKfycbyAphKwhP3HVxKfW0j9d62eMc5eOBG6sViC4KS4bGzDxqknnrDXFwmRwmUplUPLdG_O/exec"
    return axios(URL, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        setLoader(false)
        reset()
      })
      .catch(error => {
        console.log({ error })
      })
  }
  return (
    <section className="connect_block blog_detail">
      <div className="container p-0">
        <h2 className="split-text">{newsletterForm.title[language]} </h2>
        <div className="connect_grey">
          <div className="connect_box">
            <div className="containerr">
              <section className="mutistep_form w-100">
                <div className="phoneclr p-0">
                  <div className="row text_side m-0 p-0">
                    <div className="txt_block">
                      <div className="lftbrdr">
                        <h4 className="p-0 split-text">
                          {newsletterForm.formTitle[language]}
                        </h4>
                        <p>{newsletterForm.formText[language]}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <form
                  id="regForm"
                  action=""
                  onSubmit={handleSubmit(formSubmit)}
                >
                  <div className="tab" style={{ display: "block" }}>
                    <div className="form_flx">
                      {newsletterForm.form.map(frm => (
                        <div className="inp_one" key={frm.name}>
                          <label>{frm.label[language]}</label>
                          <input
                            name={frm.name}
                            {...register(`${frm.name}`, {
                              ...formValidation[frm.name],
                              required: frm.errorMsg[language],
                            })}
                            onChange={({ target }) => handleInputChange(target)}
                            placeholder=""
                          />
                          <p className="invalidd" style={{ display: "block" }}>
                            {errors[frm.name] && errors[frm.name].message}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="btn_field">
                    <button type="submit" className="blogbtn">
                      {isLoading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        <>
                          {newsletterForm.buttonText[language]}
                          <i className="arrow" />
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
